import { buildCollection, buildSchema, buildProperty } from '@camberi/firecms';
import { EntityPaths, daysInSeconds } from '../constants';

const ONE_WEEK = daysInSeconds(7);

const authorSchema = buildSchema({
  name: 'Author',
  properties: {
    name: {
      title: 'Name',
      validation: {
        required: true,
        unique: true,
      },
      dataType: 'string',
      columnWidth: 300,
    },
    profilePhoto: buildProperty({
      title: 'Profile Photo',
      dataType: 'string',
      config: {
        storageMeta: {
          mediaType: 'image',
          storagePath: 'images',
          acceptedFiles: ['image/*'],
          metadata: {
            cacheControl: `public, max-age=${ONE_WEEK}, s-maxage=${ONE_WEEK}`,
          },
        },
      },
      columnWidth: 200,
    }),
    hyperlink: {
      title: 'Hyperlink',
      dataType: 'string',
      columnWidth: 300,
    },
    linkedInLink: {
      title: 'LinkedIn Link',
      dataType: 'string',
      columnWidth: 300,
    },
    youTubeLink: {
      title: 'YouTube Link',
      dataType: 'string',
      columnWidth: 300,
    },
    instagramLink: {
      title: 'Instagram Link',
      dataType: 'string',
      columnWidth: 300,
    },
    twitterLink: {
      title: 'Twitter Link',
      dataType: 'string',
      columnWidth: 300,
    },
    facebookLink: {
      title: 'Facebook Link',
      dataType: 'string',
      columnWidth: 300,
    },
    biography: {
      title: 'Biography',
      validation: { required: true },
      config: { multiline: true },
      dataType: 'string',
      columnWidth: 900,
    },
  },
});

export const authorCollection = buildCollection({
  path: EntityPaths.authors,
  schema: authorSchema,
  name: 'Authors',
  permissions: () => ({
    edit: true,
    create: true,
    delete: true,
  }),
});
