import { buildCollection, buildSchema } from '@camberi/firecms';
import { EntityPaths } from '../constants';

const permissionWhitelistSchema = buildSchema({
  name: 'Permission Whitelist',
  properties: {
    enabled: {
      title: 'Enabled',
      dataType: 'boolean',
      columnWidth: 100,
    },
    name: {
      title: 'Name',
      validation: { required: true },
      dataType: 'string',
      columnWidth: 300,
    },
    feature: {
      title: 'Feature',
      dataType: 'string',
      validation: { required: true },
      config: {
        enumValues: { staging: 'Staging' },
      },
      columnWidth: 150,
    },
    allowEmails: {
      title: 'Allowed Emails',
      dataType: 'array',
      of: {
        dataType: 'string',
        validation: { required: true },
      },
      validation: { required: true },
      columnWidth: 400,
    },
  },
});

export const permissionWhitelistCollection = buildCollection({
  path: EntityPaths.permissionWhitelists,
  schema: permissionWhitelistSchema,
  name: 'Permission Whitelists',
  permissions: () => ({
    edit: true,
    create: true,
    delete: true,
  }),
});
