export const daysInSeconds = (days: number) => 60 * 60 * 24 * days;

export enum TrickTipCategory {
  'downhill' = 'downhill',
  'freestyle' = 'freestyle',
  'basics' = 'basics',
  'dance' = 'dance',
}

export const TrickTipCategoryWithDisplayNames = {
  [TrickTipCategory.downhill]: 'Downhill',
  [TrickTipCategory.freestyle]: 'Freestyle',
  [TrickTipCategory.basics]: 'Basics',
  [TrickTipCategory.dance]: 'Dance',
};

export enum Difficulty {
  'easy' = 'easy',
  'medium' = 'medium',
  'hard' = 'hard',
}

export const DifficultyWithDisplayNames = {
  [Difficulty.easy]: 'Easy',
  [Difficulty.medium]: 'Medium',
  [Difficulty.hard]: 'Hard',
};

export enum Tags {
  'noComply' = 'noComply',
  'grabTrick' = 'grabTrick',
  'flipTrick' = 'flipTrick',
  'spinTrick' = 'spinTrick',
  'frontside' = 'frontside',
  'backside' = 'backside',
  'landInSwitch' = 'landInSwitch',
}

export const TagsWithDisplayNames = {
  [Tags.noComply]: 'No-comply',
  [Tags.grabTrick]: 'Grab trick',
  [Tags.flipTrick]: 'Flip trick',
  [Tags.spinTrick]: 'Spin trick',
  [Tags.frontside]: 'Frontside',
  [Tags.backside]: 'Backside',
  [Tags.landInSwitch]: 'Land in Switch',
};

export enum ArticleCategory {
  'community' = 'community',
  'events' = 'events',
  'interview' = 'interview',
  'gear' = 'gear',
  'tips' = 'tips',
  'video' = 'video',
  'industryNews' = 'industryNews',
  'guide' = 'guide',
}

export const ArticleCategoryWithDisplayNames = {
  [ArticleCategory.community]: 'Community',
  [ArticleCategory.events]: 'Events',
  [ArticleCategory.interview]: 'Interview',
  [ArticleCategory.gear]: 'Gear',
  [ArticleCategory.tips]: 'Tips',
  [ArticleCategory.video]: 'Video',
  [ArticleCategory.industryNews]: 'Industry News',
  [ArticleCategory.guide]: 'Guide',
};

export const EntityPaths = {
  articles: 'articles',
  authors: 'authors',
  trickTips: 'trick-tips',
  permissionWhitelists: 'permission-whitelist',
};

export const EmptyEntity = {
  id: '',
  path: '',
  values: {
    name: '',
  },
};
