import { DataSource } from '@camberi/firecms';
import { fetchCollection } from './fetchCollection';
import { fetchEntity } from './fetchEntity';
import { saveEntity } from './saveEntity';
import { deleteEntity } from './deleteEntity';
import { checkUniqueField } from './checkUniqueField';

export const useCustomDataSource = (accessToken: string): DataSource => {
  return {
    fetchCollection: fetchCollection(accessToken),
    fetchEntity: fetchEntity(accessToken),
    checkUniqueField: checkUniqueField(accessToken),
    saveEntity: saveEntity(accessToken),
    deleteEntity: deleteEntity(accessToken),
  };
};
