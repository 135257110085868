import { buildCollection, buildProperty, buildSchema } from '@camberi/firecms';
import {
  ArticleCategoryWithDisplayNames,
  EntityPaths,
  daysInSeconds,
} from '../constants';

const ONE_WEEK = daysInSeconds(7);

const articleSchema = buildSchema({
  name: 'Article',
  properties: {
    name: {
      title: 'Name',
      validation: { required: true },
      dataType: 'string',
      columnWidth: 200,
    },
    title: {
      title: 'Title (for SEO purposes)',
      dataType: 'string',
      columnWidth: 200,
    },
    published: ({ values }) =>
      buildProperty({
        title: 'Published',
        dataType: 'boolean',
        columnWidth: 100,
        disabled:
          !values.slug ||
          !values.seoMetadata ||
          !values.thumbnail ||
          !values.tags ||
          !values.mainCategory ||
          !values.categories ||
          !values.author ||
          !values.content,
      }),
    slug: {
      title: 'Slug',
      validation: {
        unique: true,
        matches: /^\S+$/,
        requiredMessage: 'Slug cannot contain spaces',
      },
      dataType: 'string',
      columnWidth: 200,
    },
    seoMetadata: {
      title: 'SEO Metadata',
      dataType: 'string',
      config: { multiline: true },
      columnWidth: 300,
    },
    thumbnail: buildProperty({
      title: 'Thumbnail',
      dataType: 'string',
      config: {
        storageMeta: {
          mediaType: 'image',
          storagePath: 'images',
          acceptedFiles: ['image/*'],
          metadata: {
            cacheControl: `public, max-age=${ONE_WEEK}, s-maxage=${ONE_WEEK}`,
          },
        },
      },
      columnWidth: 200,
    }),
    thumbnailAlt: buildProperty({
      title: 'Thumbnail Text',
      dataType: 'string',
      columnWidth: 200,
    }),
    mainCategory: {
      title: 'Main Category',
      dataType: 'string',
      columnWidth: 200,
      config: {
        enumValues: ArticleCategoryWithDisplayNames,
      },
    },
    categories: {
      title: 'Categories',
      dataType: 'array',
      of: {
        dataType: 'string',
        validation: { required: true },
        config: {
          enumValues: ArticleCategoryWithDisplayNames,
        },
      },
      columnWidth: 200,
    },
    tags: {
      title: 'Tags',
      dataType: 'array',
      of: {
        dataType: 'string',
        validation: { required: true },
      },
      columnWidth: 200,
    },
    author: {
      title: 'Author',
      dataType: 'reference',
      path: EntityPaths.authors,
      columnWidth: 200,
    },
    content: {
      title: 'Content',
      dataType: 'string',
      config: { markdown: true },
      columnWidth: 600,
    },
  },
});

export const articleCollection = buildCollection({
  path: EntityPaths.articles,
  schema: articleSchema,
  name: 'Articles',
  permissions: () => ({
    edit: true,
    create: true,
    delete: true,
  }),
});
