import { FetchEntityProps, Entity } from '@camberi/firecms';
import axios from 'axios';
import { mapDBDataToEntity } from './mapper';
import { EmptyEntity } from '../constants';

type FetchEntity<T> = ({
  path,
  entityId,
  schema,
}: FetchEntityProps<T>) => Promise<Entity<T>>;

export const fetchEntity = (accessToken: string) => {
  const _fetchEntity: FetchEntity<any> = async ({
    path,
    entityId,
    // schema,
  }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/${path}/${entityId}?staging=true`,
        { headers: { Authorization: 'Bearer ' + accessToken } },
      );
      return mapDBDataToEntity({ path, data: data.data });
    } catch (error) {
      console.error(error);
      return EmptyEntity;
    }
  };

  return _fetchEntity;
};
