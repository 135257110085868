import { Property } from '@camberi/firecms';
import axios from 'axios';

type CheckUniqueField = (
  path: string,
  name: string,
  value: any,
  property: Property,
  entityId?: string | undefined,
) => Promise<boolean>;

export const checkUniqueField = (accessToken: string) => {
  const _checkUniqueField: CheckUniqueField = async (
    path,
    name,
    value,
    _,
    entityId,
  ) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/${path}/check-unique`,
        { name, value, id: entityId },
        { headers: { Authorization: 'Bearer ' + accessToken } },
      );
      return data.data.unique;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return _checkUniqueField;
};
