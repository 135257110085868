import { SaveEntityProps, Entity } from '@camberi/firecms';
import axios from 'axios';
import { mapDBDataToEntity, mapEntityToDBRelationshipIds } from './mapper';
import { EmptyEntity } from '../constants';

type SaveEntity<T> = ({
  path,
  entityId,
  values,
  schema,
  status,
}: SaveEntityProps<T>) => Promise<Entity<T>>;

export const saveEntity = (accessToken: string) => {
  const _saveEntity: SaveEntity<any> = async ({
    path,
    entityId,
    values,
    // schema,
    // status,
  }) => {
    try {
      const requestUrl = `${process.env.REACT_APP_API_URL}/${path}${
        entityId ? `/${entityId}` : ''
      }`;

      const { data } = await axios.post(
        requestUrl,
        mapEntityToDBRelationshipIds(values),
        { headers: { Authorization: 'Bearer ' + accessToken } },
      );

      return mapDBDataToEntity({ path, data: data.data });
    } catch (error) {
      console.error(error);
      return EmptyEntity;
    }
  };

  return _saveEntity;
};
