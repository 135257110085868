import { DeleteEntityProps } from '@camberi/firecms';
import axios from 'axios';

type DeleteEntity<T> = ({ entity }: DeleteEntityProps<T>) => Promise<void>;

export const deleteEntity = (accessToken: string) => {
  const _deleteEntity: DeleteEntity<any> = async ({ entity }) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/${entity.path}/${entity.id}`,
        { headers: { Authorization: 'Bearer ' + accessToken } },
      );
    } catch (error) {
      console.error(error);
    }
  };

  return _deleteEntity;
};
