import { Entity, EntityReference } from '@camberi/firecms';
import { Article } from '../types/article';
import { Author } from '../types/author';
import { TrickTip } from '../types/trick-tip';
import { PermissionWhitelist } from '../types/permission-whitelist';
import { EntityPaths, EmptyEntity } from '../constants';

export const mapEntityToDBRelationshipIds = (values: any) => {
  if (values.author) {
    values.authorId = values.author.id;
  }
  if (values.nextTricks) {
    values.nextTrickIds = values.nextTricks.map(({ id }: { id: string }) => id);
  }
  if (values.prerequisiteTricks) {
    values.prerequisiteTrickIds = values.prerequisiteTricks.map(
      ({ id }: { id: string }) => id,
    );
  }
  return values;
};

interface MapDBDataToEntityArgs {
  path: string;
  data: Article | Author | TrickTip | PermissionWhitelist;
}

export type CMSEntity =
  | ArticleEntity
  | AuthorEntity
  | TrickTipEntity
  | PermissionWhitelistEntity;

export const mapDBDataToEntity = ({
  path,
  data,
}: MapDBDataToEntityArgs): CMSEntity => {
  switch (path) {
    case EntityPaths.articles:
      return mapArticle(data);
    case EntityPaths.authors:
      return mapAuthor(data);
    case EntityPaths.trickTips:
      return mapTrickTip(data);
    case EntityPaths.permissionWhitelists:
      return mapPermissionWhitelist(data as PermissionWhitelist);
    default:
      return EmptyEntity;
  }
};

type ArticleEntity = Entity<
  Omit<Article, 'id' | 'author'> & { author?: EntityReference }
>;

const mapArticle = ({
  id,
  name,
  title,
  published,
  slug,
  thumbnail,
  thumbnailAlt,
  seoMetadata,
  mainCategory,
  categories,
  tags,
  content,
  author,
}: Article): ArticleEntity => ({
  id,
  path: EntityPaths.articles,
  values: {
    name,
    title,
    published,
    slug,
    thumbnail,
    thumbnailAlt,
    seoMetadata,
    mainCategory,
    categories,
    tags,
    content,
    author: author
      ? new EntityReference(author.id, EntityPaths.authors)
      : undefined,
  },
});

type AuthorEntity = Entity<Omit<Author, 'id'>>;

const mapAuthor = ({
  id,
  name,
  biography,
  hyperlink,
  linkedInLink,
  youTubeLink,
  instagramLink,
  twitterLink,
  facebookLink,
  profilePhoto,
}: Author): AuthorEntity => ({
  id,
  path: EntityPaths.authors,
  values: {
    name,
    biography,
    hyperlink,
    linkedInLink,
    youTubeLink,
    instagramLink,
    twitterLink,
    facebookLink,
    profilePhoto,
  },
});

type TrickTipEntity = Entity<Omit<TrickTip, 'id'>>;

const mapTrickTip = ({
  id,
  name,
  published,
  slug,
  thumbnail,
  description,
  seoMetadata,
  vimeoVideoId,
  rider,
  instructions,
  category,
  difficulty,
  tags,
  alternateNames,
  prerequisiteTricks,
  nextTricks,
}: TrickTip): TrickTipEntity => ({
  id,
  path: EntityPaths.trickTips,
  values: {
    name,
    published,
    slug,
    thumbnail,
    description,
    seoMetadata,
    vimeoVideoId,
    rider,
    instructions,
    category,
    difficulty,
    tags,
    alternateNames,
    prerequisiteTricks: prerequisiteTricks?.map(
      ({ id }) => new EntityReference(id, EntityPaths.trickTips),
    ),
    nextTricks: nextTricks?.map(
      ({ id }) => new EntityReference(id, EntityPaths.trickTips),
    ),
  },
});

type PermissionWhitelistEntity = Entity<Omit<PermissionWhitelist, 'id'>>;

const mapPermissionWhitelist = ({
  id,
  name,
  feature,
  enabled,
  allowEmails,
}: PermissionWhitelist): PermissionWhitelistEntity => ({
  id,
  path: EntityPaths.permissionWhitelists,
  values: {
    name,
    feature,
    enabled,
    allowEmails,
  },
});
