import { buildCollection, buildProperty, buildSchema } from '@camberi/firecms';
import {
  TrickTipCategoryWithDisplayNames,
  DifficultyWithDisplayNames,
  TagsWithDisplayNames,
  EntityPaths,
  daysInSeconds,
} from '../constants';

const ONE_WEEK = daysInSeconds(7);

const trickTipSchema = buildSchema({
  name: 'TrickTip',
  properties: {
    name: {
      title: 'Name',
      validation: { required: true },
      dataType: 'string',
      columnWidth: 200,
    },
    published: ({ values }) =>
      buildProperty({
        title: 'Published',
        dataType: 'boolean',
        columnWidth: 100,
        disabled:
          !values.slug ||
          !values.description ||
          !values.seoMetadata ||
          !values.thumbnail ||
          !values.vimeoVideoId ||
          !values.category ||
          !values.difficulty ||
          !values.instructions ||
          !values.rider ||
          !(values.rider as Record<string, string>).name ||
          !(values.rider as Record<string, string>).link,
      }),
    slug: {
      title: 'Slug',
      validation: {
        unique: true,
        matches: /^\S+$/,
        requiredMessage: 'Slug cannot contain spaces',
      },
      dataType: 'string',
      columnWidth: 200,
    },
    alternateNames: {
      title: 'Alternate Names',
      dataType: 'string',
      columnWidth: 200,
    },
    description: {
      title: 'Description',
      config: { multiline: true },
      dataType: 'string',
      columnWidth: 300,
    },
    seoMetadata: {
      title: 'SEO Metadata',
      dataType: 'string',
      config: { multiline: true },
      columnWidth: 300,
    },
    thumbnail: buildProperty({
      title: 'Thumbnail',
      dataType: 'string',
      config: {
        storageMeta: {
          mediaType: 'image',
          storagePath: 'images',
          acceptedFiles: ['image/*'],
          metadata: {
            cacheControl: `public, max-age=${ONE_WEEK}, s-maxage=${ONE_WEEK}`,
          },
        },
      },
      columnWidth: 200,
    }),
    vimeoVideoId: {
      title: 'Vimeo Video ID',
      dataType: 'string',
      description: 'Can be found on the Vimeo account dashboard',
      columnWidth: 200,
    },
    rider: {
      title: 'Rider',
      dataType: 'map',
      properties: {
        name: {
          title: 'Name',
          dataType: 'string',
        },
        link: {
          title: 'Link',
          dataType: 'string',
        },
      },
      columnWidth: 200,
    },
    instructions: {
      title: 'Instructions',
      dataType: 'array',
      of: {
        dataType: 'map',
        properties: {
          shortText: {
            title: 'Short text',
            dataType: 'string',
            validation: { required: true },
            config: { multiline: true },
          },
          longText: {
            title: 'Long text',
            dataType: 'string',
            validation: { required: true },
            config: { multiline: true },
          },
          proTip: {
            title: 'Pro-tip',
            dataType: 'string',
            config: { multiline: true },
          },
          videoUrl: {
            title: 'Video URL',
            dataType: 'string',
          },
        },
      },
      columnWidth: 600,
    },
    category: {
      title: 'Category',
      dataType: 'string',
      config: {
        enumValues: TrickTipCategoryWithDisplayNames,
      },
      columnWidth: 150,
    },
    difficulty: {
      title: 'Difficulty',
      dataType: 'string',
      config: {
        enumValues: DifficultyWithDisplayNames,
      },
      columnWidth: 150,
    },
    tags: {
      title: 'Tags',
      dataType: 'array',
      of: {
        dataType: 'string',
        config: {
          enumValues: TagsWithDisplayNames,
        },
      },
      columnWidth: 300,
    },
    prerequisiteTricks: {
      title: 'Prerequisites',
      dataType: 'array',
      of: {
        dataType: 'reference',
        path: EntityPaths.trickTips,
      },
      columnWidth: 200,
    },
    nextTricks: {
      title: 'Next Tricks',
      dataType: 'array',
      of: {
        dataType: 'reference',
        path: EntityPaths.trickTips,
      },
      columnWidth: 200,
    },
  },
});

export const trickTipCollection = buildCollection({
  path: EntityPaths.trickTips,
  schema: trickTipSchema,
  name: 'TrickTips',
  permissions: () => ({
    edit: true,
    create: true,
    delete: true,
  }),
});
