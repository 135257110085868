import { GoogleAuthProvider } from 'firebase/auth';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import { useState } from 'react';
import {
  Authenticator,
  CircularProgressCenter,
  createCMSDefaultTheme,
  FirebaseLoginView,
  FireCMS,
  NavigationBuilder,
  NavigationRoutes,
  Scaffold,
  SideEntityDialogs,
  useFirebaseAuthDelegate,
  useFirebaseStorageSource,
  useInitialiseFirebase,
} from '@camberi/firecms';
import {
  articleCollection,
  authorCollection,
  trickTipCollection,
  permissionWhitelistCollection,
} from './models';
import { useCustomDataSource } from './useCustomDataSource';
import 'typeface-rubik';
import 'typeface-space-mono';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const DEFAULT_SIGN_IN_OPTIONS = [GoogleAuthProvider.PROVIDER_ID];

/**
 * This is an example of how to use the components provided by FireCMS for
 * a better customisation.
 * @constructor
 */
const CustomCMSApp = () => {
  const signInOptions = DEFAULT_SIGN_IN_OPTIONS;

  const [accessToken, setAccessToken] = useState('');

  const navigation: NavigationBuilder = () => ({
    collections: [
      articleCollection,
      authorCollection,
      trickTipCollection,
      permissionWhitelistCollection,
    ],
  });

  const myAuthenticator: Authenticator = ({ user }) => {
    setAccessToken(user.accessToken);

    if (!(user?.email.split('@')[1] === 'ampskate.com')) {
      throw new Error('Access denied');
    }
    return true;
  };

  const {
    firebaseApp,
    firebaseConfigLoading,
    configError,
    firebaseConfigError,
  } = useInitialiseFirebase({ firebaseConfig });

  const customDataSource = useCustomDataSource(accessToken);
  const storageSource = useFirebaseStorageSource({ firebaseApp });
  const authDelegate = useFirebaseAuthDelegate({
    firebaseApp,
    signInOptions,
  });

  if (configError) {
    return <div> {configError} </div>;
  }

  if (firebaseConfigError) {
    return (
      <div>
        It seems like the provided Firebase config is not correct. If you are
        using the credentials provided automatically by Firebase Hosting, make
        sure you link your Firebase app to Firebase Hosting.
      </div>
    );
  }

  if (firebaseConfigLoading || !firebaseApp) {
    return <CircularProgressCenter />;
  }

  return (
    <Router>
      <FireCMS
        navigation={navigation}
        authDelegate={authDelegate}
        authentication={myAuthenticator}
        dataSource={customDataSource}
        storageSource={storageSource}
        entityLinkBuilder={({ entity }) =>
          `https://console.firebase.google.com/project/${firebaseApp.options.projectId}/firestore/data/${entity.path}/${entity.id}`
        }
      >
        {({ context, mode, loading }) => {
          const theme = createCMSDefaultTheme({ mode });

          let component;
          if (loading) {
            component = <CircularProgressCenter />;
          } else if (!context.authController.canAccessMainView) {
            component = (
              <FirebaseLoginView
                skipLoginButtonEnabled={false}
                signInOptions={signInOptions}
                firebaseApp={firebaseApp}
                authDelegate={authDelegate}
              />
            );
          } else {
            component = (
              <Scaffold name={'Amp Skate CMS'}>
                <NavigationRoutes />
                <SideEntityDialogs />
              </Scaffold>
            );
          }

          return (
            <ThemeProvider theme={theme}>
              <CssBaseline />
              {component}
            </ThemeProvider>
          );
        }}
      </FireCMS>
    </Router>
  );
};

export default CustomCMSApp;
