import { FetchCollectionProps, Entity } from '@camberi/firecms';
import axios from 'axios';
import { mapDBDataToEntity } from './mapper';

type FetchCollection<T> = ({
  path,
  schema,
  filter,
  limit,
  startAfter,
  orderBy,
  order,
  searchString,
}: FetchCollectionProps<T>) => Promise<Entity<T>[]>;

export const fetchCollection = (accessToken: string) => {
  const _fetchCollection: FetchCollection<any> = async ({
    path,
    // schema,
    // filter,
    // limit,
    // startAfter,
    // orderBy,
    // order,
    // searchString,
  }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/${path}?staging=true&orderBy=alpha`,
        { headers: { Authorization: 'Bearer ' + accessToken } },
      );
      return data.data.map((d: any) => mapDBDataToEntity({ path, data: d }));
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  return _fetchCollection;
};
